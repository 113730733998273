import type { FieldData } from 'rc-field-form/es/interface'

import { authorizationEndpoints } from '@redux/features/authorization/api'
import { setIsLoadingAuth } from '@redux/features/authorization/authorization.slice'
import type { RootState } from '@redux/store'
import { createAsyncThunk } from '@reduxjs/toolkit'

import authStorage from '@storageServices/storageEntities/auth'
import type { LoginFormType } from '@utils/forms/authorization/login'

import checkEmail from '../checkEmail'
import login from '../login'

export default createAsyncThunk<
  void,
  {
    loginForm: Required<LoginFormType>
    setErrors: (fields: FieldData[]) => void
  },
  { state: RootState }
>('authorization/handlerLogin', async (data, { dispatch, getState }) => {
  const { isCheckedEmail } = getState().authorization
  const isAuthToken = authStorage.getValueByKey('X-Auth-Token')

  if (isAuthToken) {
    void dispatch(setIsLoadingAuth(true))
    await dispatch(authorizationEndpoints.postLogout.initiate()).finally(() => {
      void dispatch(checkEmail(data))
      void dispatch(setIsLoadingAuth(false))
    })
    return
  }

  isCheckedEmail ? void dispatch(login(data)) : void dispatch(checkEmail(data))
})
