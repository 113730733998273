import { TRIP_MODE } from '@redux/features/search/constants/search.constants'
import { searchEndpoints } from '@redux/features/search/search.api'
import type { AffiliateProgramStateType } from '@redux/features/search/types/affiliateProgram'
import type { ISearchState } from '@redux/features/search/types/searchState'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

const initialState: ISearchState = {
  tripMode: TRIP_MODE.ONE_WAY,
  withLayover: false,
}

export const searchSlice = createSlice({
  extraReducers: builder => {
    builder.addMatcher(searchEndpoints.getSearchHistory.matchFulfilled, (state, { payload }) => {
      state.tripMode = payload?.[0]?.round_trip ? TRIP_MODE.ROUND_TRIP : TRIP_MODE.ONE_WAY
    })
  },
  initialState,
  name: 'search',
  reducers: {
    setAffiliateProgram: (state, action: PayloadAction<AffiliateProgramStateType>) => {
      state.affiliateProgram = action.payload
    },
    setDepartureDate: (state, action: PayloadAction<string>) => {
      state.departureDate = action.payload
    },
    setWithLayover: (state, action: PayloadAction<boolean>) => {
      state.withLayover = action.payload
    },
    switchTripMode: (state, action: PayloadAction<TRIP_MODE | undefined>) => {
      state.tripMode =
        action.payload ?? (state.tripMode === TRIP_MODE.ROUND_TRIP ? TRIP_MODE.ONE_WAY : TRIP_MODE.ROUND_TRIP)
    },
  },
})

export const { setAffiliateProgram, setDepartureDate, setWithLayover, switchTripMode } = searchSlice.actions

export default searchSlice.reducer
